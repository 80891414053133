/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import CurtainsClosedIcon from "@mui/icons-material/CurtainsClosed";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import CustomModal from "../../components/modals/Index";
import MoveJobs from "../../components/modals/MoveJobs";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData, setModal } from "../../features/auth/authSlice";
import {
  fetchStates,
  getDefaultState,
  setPrintMod,
} from "../../features/defaultSlice";
import {
  closeJob,
  closeModal,
  fetchData,
  getDownloadData,
  getJobsData,
  markForAddressVerification,
  openModal,
  setCurrentJob,
  setPagination,
} from "../../features/jobs/jobs";
import { clearVendorData } from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import VendorAssignment from "../manualReassignment/VendorAssignment";
import ViewVendor from "./ViewVendor";

const PendingJobs = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { currentJob, modal, loading, pagination, downloadData, ...jobsData } =
      useSelector(getJobsData),
    { pathname, state } = useLocation(),
    { appType, ...authData } = useSelector(getAuthData),
    [checkboxData, setcheckboxData] = useState([]),
    [adminAction, setAdminAction] = useState({}),
    [showText, setShowText] = useState(true),
    pendingJobs = jobsData[camelCasedPathName()],
    { states, printMod } = useSelector(getDefaultState),
    [filter, setFilter] = useState({
      queue: "",
      vendor: "",
      state: "",
      status: "",
    });

  const stateOption = useMemo(() => {
    const result = states?.map((state) => ({
      name: state?.Name,
      url: "#",
      id: state?.Id,
    }));
    filter.state && result.unshift({ name: "All", id: "", url: "#" });

    return result;
  }, [states.length, filter.state]);

  useEffect(() => {
    dispatch(clearVendorData());
    if (state) {
      dispatch(setPagination({ page: 1, search: state }));
    } else dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Pending Jobs" }));
    getRole() !== Roles.bank && dispatch(fetchStates());
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };
  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [
    pagination.search,
    pagination.page,
    pagination.pageSize,
    pagination.stateId,
  ]);

  let data = pendingJobs?.map((d) => {
    let model = {};

    if (AppType.cs === appType) model["CS ID"] = d?.AcctRefID;
    else model["CAV ID"] = d?.AcctRefID;

    if (getRole() !== Roles.vendor)
      model["Account Number"] = d?.AcctNumber || "N/A";
    model["Account Name"] = capitalize(d?.AcctName) || "N/A";

    if (AppType.cs === appType) model["RC Number"] = d?.RCNumber || "N/A";
    if (AppType.cs === appType)
      model["Date of Incorporation"] = d?.DateOfIncorporation
        ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
        : "N/A";
    else {
      model["Address"] = capitalize(d?.AcctAddress) || "N/A";
      model["Updated Address"] = capitalize(d?.UpdatedAcctAddress) || "N/A";
    }

    model = {
      ...model,
      State: capitalize(d?.State?.Name) || "N/A",
      "Solicitor's Name":
        capitalize(
          d?.AssignedVendor?.VendorPreferredName ||
            d?.AssignedVendor?.VendorName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorName
        ) || "N/A",
      ...(getRole() !== Roles.vendor && {
        "Assigned By": d?.InitiatedByUser?.firstName
          ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
          : "N/A",
      }),
      "Assigned Date": d?.DateAssigned
        ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
        : "N/A",
      _data: d,
    };

    model._data = d;

    return model;
  });

  const dropdownData = {
    visible: true,
    type: "dropdown",
    icon: <MoreHorizIcon />,
    data: [
      {
        text: "View solicitor's form",
        action: (_, state) => {
          dispatch(openModal("open"));
          dispatch(setCurrentJob(state));
        },
      },
    ],
  };

  if (AppType.cav === appType)
    dropdownData.data.unshift({
      text: "Vague Address",
      action: async (_, state) => {
        const res = await dispatch(
          markForAddressVerification({
            status: "VAGUE",
            accountId: state.AcctRefID,
          })
        ).unwrap();
        res?.success && (await dispatch(fetchData()));
      },
    });

  const refresh = async () => {
    dispatch(closeModal("open"));
    dispatch(setModal({ open: false }));
    setcheckboxData([]);
    await dispatch(fetchData());
  };

  const checkboxAction = {
    setValues: (data) => setcheckboxData(data),
    values: checkboxData,
  };

  const initBulkAction = (meta, idx) => {
    if (idx && meta?.title?.toLowerCase() !== "move") {
      setAdminAction(meta);
      dispatch(setModal({ close: true }));
    } else meta.action();
  };
  const buttonMeta = [
    {
      title: "Reassign",
      Icon: RecyclingIcon,
      action: (_) => dispatch(setModal({ open: true })),
    },
    {
      title: "Close",
      Icon: CurtainsClosedIcon,
      action: closeJob,
      loading: modal.loader.closedJobs,
    },
    {
      title: "Move",
      Icon: DriveFileMoveIcon,
      action: (_) => dispatch(openModal("close")),
    },
  ];

  const buttonStyle = useCallback(
    (i) => ({
      borderRadius: 0,
      minWidth: "50px",
      border: `${showText ? "0px" : "1px"} solid red`,
      borderRight:
        i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid red`,
      textTransform: "capitalize",
      fontSize: "26px",
      "&:hover": { backgroundColor: "#D70900", color: "white" },
      ".MuiLoadingButton-loadingIndicatorCenter": {
        color: "var(--C_primary) !important",
      },
      "&:disabled": {
        border: `${showText ? "0px" : "1px"} solid grey`,
        borderRight:
          i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid grey`,
        textTransform: "capitalize",
      },
    }),
    [showText]
  );

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex flex-wrap items-end justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Pending Jobs
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Accounts awaiting verification from solicitors.
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
          />
          {getRole() !== Roles.bank && (
            <SelectButton
              btTextClass="bg-white text-dark_red border border-[#F8F7F7]"
              options={stateOption}
              title={filter.state || "State"}
              download={false}
              onClick={(state) => {
                setFilter({
                  ...filter,
                  state: state?.id === "" ? "" : state?.name,
                });
                dispatch(setPagination({ stateId: state?.id }));
              }}
            />
          )}
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>
      <div>
        {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
          <div className="flex justify-between mt-5 flex-wrap gap-y-5 items-center">
            <div>
              {buttonMeta.map((meta, i) => (
                <Tooltip
                  key={i}
                  className="py-0 px-0"
                  title={meta.title + " Jobs"}
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={(_) => initBulkAction(meta, i)}
                      color="error"
                      disabled={checkboxData.length ? false : true}
                      sx={(_) => buttonStyle(i, showText)}
                    >
                      <meta.Icon />{" "}
                      {showText && (
                        <span className="text-[15px] ml-1"> {meta.title} </span>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              ))}

              <Tooltip
                className="py-0 px-0"
                title={showText ? "Collapse" : "Expand"}
                placement="top"
              >
                <Button
                  color="error"
                  onClick={(_) => setShowText(!showText)}
                  sx={{ minWidth: "20px", borderRadius: "30px", ml: "2em" }}
                  className="ml-5"
                >
                  {showText ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
                </Button>
              </Tooltip>
            </div>

            <Search {...{ value: pagination.search, onSearch: handleSearch }} />
          </div>
        )}
        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            data,
            action: getRole() === Roles.vendor && dropdownData,
            xclass: "py-2",
            tableMsg: [
              "No Pending Jobs",
              "Accounts waiting verification from solicitors",
              "will be listed in this folder",
            ],
          }}
        />
      </div>
      <CustomModal
        {...{
          title: "Solicitor's Form",
          open: modal.open,
          close: (_) => dispatch(closeModal("open")),
        }}
      >
        <ViewVendor
          {...{
            data: currentJob,
            refresh,
            closeModal: (_) => dispatch(closeModal("open")),
          }}
        />
      </CustomModal>

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(setModal({ open: false })),
              open: authData.modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(setModal({ open: false })),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <CustomModal
            {...{
              title: `Move ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              }`,
              open: modal.close,
              close: (_) => dispatch(closeModal("close")),
            }}
          >
            <MoveJobs
              {...{
                closeModal: (_) => dispatch(closeModal("close")),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: authData.modal.close,
              close: (_) => dispatch(setModal({ close: false })),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading: modal.loader.closedJobs,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default PendingJobs;
