/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import CurtainsClosedIcon from "@mui/icons-material/CurtainsClosed";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RecyclingIcon from "@mui/icons-material/Recycling";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import CustomModal from "../../components/modals/Index";
import MoveJobs from "../../components/modals/MoveJobs";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData, setModal } from "../../features/auth/authSlice";
import { getDefaultState, setPrintMod } from "../../features/defaultSlice";
import {
  closeJob,
  closeModal,
  fetchData,
  getDownloadData,
  getJobsData,
  openModal,
  setCurrentJob,
  setPagination,
} from "../../features/jobs/jobs";
import { clearVendorData } from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import VendorsAssignment from "../manualReassignment/VendorAssignment";
import VendorAssignment from "../pendingAcknowledgment/VendorAssignment";
import ViewJob from "../pendingAcknowledgment/ViewJob";
import UpdateAddress from "./UpdateAddress";

const AwaitingBranchConfirmation = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { currentJob, modal, loading, pagination, downloadData, ...jobsData } =
      useSelector(getJobsData),
    { appType, ...authData } = useSelector(getAuthData),
    branchConfirmation = jobsData[camelCasedPathName()],
    { printMod } = useSelector(getDefaultState),
    [checkboxData, setcheckboxData] = useState([]),
    [adminAction, setAdminAction] = useState({}),
    [showText, setShowText] = useState(true),
    { pathname, state } = useLocation();

  useEffect(() => {
    dispatch(clearVendorData());
    dispatch(setPrintMod({ pageTitle: "Awaiting Branch Confirmation" }));
    if (state) {
      dispatch(setPagination({ page: 1, search: state }));
    } else dispatch(setPagination({ page: 1, search: "" }));
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  let data = branchConfirmation?.map((d) => ({
    ...(appType === AppType.cav
      ? { "CAV ID": d?.AcctRefID }
      : { "CS ID": d?.AcctRefID }),
    "Account Number": d?.AcctNumber || "N/A",
    "Account Name": d?.AcctName || "N/A",
    ...(appType === AppType.cav
      ? { Address: d?.AcctAddress || "N/A" }
      : {
          "RC Number": d?.RCNumber || "N/A",
          "Date of Incorporation": d?.DateOfIncorporation
            ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
            : "N/A",
        }),
    State: capitalize(d?.State?.Name || "N/A"),
    "Solicitor's Name":
      d?.AssignedVendor?.VendorPreferredName ||
      d?.AssignedVendor?.VendorName ||
      "N/A",
    ...(getRole() !== Roles.vendor && {
      "Assigned By": d?.InitiatedByUser?.firstName
        ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
        : "N/A",
    }),
    "Assigned Date": d?.DateAssigned
      ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
      : "N/A",
    "Completed Date": d?.DateConfirmed
      ? dayjs(d?.DateConfirmed).format("MMM DD, YYYY")
      : "N/A",
    Status: d?.AddedInfo,
    _data: d,
  }));

  const dropdownData = {
    visible: true,
    type: "icon",
    icon: <VisibilityIcon />,
    action: (_, state) => {
      dispatch(openModal("open"));
      dispatch(setCurrentJob(state?._data));
    },
  };

  const checkboxAction = {
    setValues: (data) => setcheckboxData(data),
    values: checkboxData,
  };

  const initBulkAction = (meta, idx) => {
    if (idx && meta?.title?.toLowerCase() !== "move") {
      setAdminAction(meta);
      dispatch(setModal({ close: true }));
    } else meta.action();
  };
  const buttonMeta = [
    {
      title: "Reassign",
      Icon: RecyclingIcon,
      action: (_) => dispatch(setModal({ open: true })),
    },
    {
      title: "Close",
      Icon: CurtainsClosedIcon,
      action: closeJob,
      loading: modal.loader.closedJobs,
    },
    {
      title: "Move",
      Icon: DriveFileMoveIcon,
      action: (_) => dispatch(openModal("close")),
    },
  ];

  const refresh = async () => {
    dispatch(closeModal());
    dispatch(setModal({ open: false }));
    setcheckboxData([]);
    await dispatch(fetchData());
  };
  const buttonStyle = useCallback(
    (i) => ({
      borderRadius: 0,
      minWidth: "50px",
      border: `${showText ? "0px" : "1px"} solid red`,
      borderRight:
        i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid red`,
      textTransform: "capitalize",
      fontSize: "26px",
      "&:hover": { backgroundColor: "#D70900", color: "white" },
      ".MuiLoadingButton-loadingIndicatorCenter": {
        color: "var(--C_primary) !important",
      },
      "&:disabled": {
        border: `${showText ? "0px" : "1px"} solid grey`,
        borderRight:
          i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid grey`,
        textTransform: "capitalize",
      },
    }),
    [showText]
  );

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex items-end gap-2 flex-wrap justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Awaiting Confirmation
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Accounts{" "}
            {appType === AppType.cav
              ? "pending for confirmation."
              : "flagged as unsuccessful by solicitors."}
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{
              componentRef: tableRef,
              documentTitle: setPrintMod.pageTitle,
            }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>

      <div className="">
        {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
          <div className="flex justify-between mt-5 flex-wrap gap-y-5">
            <div>
              {buttonMeta.map((meta, i) => (
                <Tooltip
                  key={i}
                  className="py-0 px-0"
                  title={meta.title + " Jobs"}
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={(_) => initBulkAction(meta, i)}
                      color="error"
                      disabled={checkboxData.length ? false : true}
                      sx={(_) => buttonStyle(i, showText)}
                    >
                      <meta.Icon />{" "}
                      {showText && (
                        <span className="text-[15px] ml-1"> {meta.title} </span>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              ))}

              <Tooltip
                className="py-0 px-0"
                title={showText ? "Collapse" : "Expand"}
                placement="top"
              >
                <Button
                  color="error"
                  onClick={(_) => setShowText(!showText)}
                  sx={{ minWidth: "20px", borderRadius: "30px", ml: "2em" }}
                  className="ml-5"
                >
                  {showText ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
                </Button>
              </Tooltip>
            </div>

            <Search
              {...{
                value: pagination.search,
                onSearch: handleSearch,
                width: "300px",
              }}
            />
          </div>
        )}

        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            data,
            action: getRole() === Roles.bank && dropdownData,
          }}
        />
      </div>

      {getRole() === Roles.bank &&
        (appType === AppType.cav ? (
          <CustomModal
            {...{
              title: "Update Address",
              close: (_) => dispatch(closeModal("open")),
              open: modal.open,
            }}
          >
            <UpdateAddress
              {...{
                closeModal: (_) => dispatch(closeModal("open")),
                data: currentJob,
                refresh: (_) => {
                  dispatch(closeModal("open"));
                  dispatch(fetchData());
                },
                openVendorModal: (_) => {
                  dispatch(closeModal("open"));
                  dispatch(setModal({ open: true }));
                },
              }}
            />
          </CustomModal>
        ) : (
          <CustomModal
            {...{
              title: "View & Action form",
              close: (_) => dispatch(closeModal("open")),
              open: modal.open,
            }}
          >
            <ViewJob
              {...{
                refresh,
                data: currentJob,
                appType,
                openVendorAssignModal: (_) => {
                  dispatch(closeModal("open"));
                  dispatch(openModal("reassign"));
                },
              }}
            />
          </CustomModal>
        ))}

      {getRole() === Roles.bank && (
        <CustomModal
          {...{
            title: "Solicitor Assign Form",
            close: (_) => dispatch(closeModal("reassign")),
            open: modal.reassign,
          }}
        >
          <VendorAssignment
            {...{
              closeModal: (_) => dispatch(closeModal("reassign")),
              data: currentJob,
              refresh: (_) => {
                dispatch(closeModal("reassign"));
                dispatch(fetchData());
              },
            }}
          />
        </CustomModal>
      )}

      {[Roles.admin, Roles.superAdmin, Roles.bank].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(setModal({ open: false })),
              open: authData.modal.open,
            }}
          >
            <VendorsAssignment
              {...{
                closeModal: (_) => dispatch(setModal({ open: false })),
                data: checkboxData.length > 0 ? checkboxData : [currentJob],
                refresh,
              }}
            />
          </CustomModal>

          <CustomModal
            {...{
              title: `Move ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              }`,
              open: modal.close,
              close: (_) => dispatch(closeModal("close")),
            }}
          >
            <MoveJobs
              {...{
                closeModal: (_) => dispatch(closeModal("close")),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: authData.modal.close,
              close: (_) => dispatch(setModal({ close: false })),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading: modal.loader.closedJobs,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};
export default AwaitingBranchConfirmation;
